<template>
  <div class="upload-wrap">
    <el-upload
      drag
      class="upload-handler"
      action="https://upload.qiniup.com"
      :show-file-list="false"
      :data="uploadParams"
      :on-change="onChange"
      :before-upload="beforeUpload"
    >
      <div @drop.prevent="onDrop" @dragover.prevent="() => {}">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <template v-if="uploadText">{{ uploadText }}</template>
          <template v-else>
            将文件拖到此处，或
            <em>点击上传</em>
          </template>
        </div>
      </div>
    </el-upload>
    <div class="upload-preview">
      <img class="preview" :src="resource" alt="预览" v-if="type === 'image' && resource" />
      <video class="preview" :src="resource" alt="预览" v-if="type === 'video' && resource" controls />
      <el-button v-if="resource" type="warning" size="mini" @click="deleteImage">删除资源</el-button>
    </div>
  </div>
</template>

<script>
import { getQiniuToken } from '@/domains/base-xgb'

export default {
  props: {
    modelValue: String,
    filetype: String,
    uploadText: String,
    showPreview: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    modelValue(src) {
      this.resource = src
    }
  },
  data() {
    return {
      qiniu: {
        Token: localStorage.getItem('qnToken')
      },
      resource: this.modelValue,
      type: this.filetype || 'image'
    }
  },
  computed: {
    uploadParams() {
      return {
        token: this.qiniu.Token
      }
    }
  },
  methods: {
    getImageFileRect(file) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = (window.URL || window.webkitURL).createObjectURL(file)
        image.onload = function () {
          resolve({ width: this.width, height: this.height })
        }
        image.onerror = function () {
          reject()
        }
      })
    },
    beforeUpload() {
      return new Promise((resolve, reject) => {
        getQiniuToken()
          .then((res) => {
            this.qiniu = res
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    onChange(file) {
      if (file.status === 'success') {
        if (file.raw.type.indexOf('video') > -1) {
          this.type = 'video'
        }
        if (file.raw.type.indexOf('image') > -1) {
          this.type = 'image'
        }
        const resource = `https://${this.qiniu.Domain}/${file.response.key}`
        this.resource = resource
        if (this.type === 'image') {
          this.getImageFileRect(file.raw).then((rect) => {
            this.$emit('rect', rect)
            this.$emit('update:modelValue', resource)
          })
        } else {
          this.$emit('update:modelValue', resource)
        }
        this.$emit('size', file.size)
        this.$message({
          type: 'success',
          message: '上传成功！'
        })
      } else if (file.status === 'fail') {
        this.$message.error('上传失败！请重新上传。')
      }
    },
    deleteImage() {
      this.resource = ''
      this.$emit('update:modelValue', '')
    },
    onDrop(e) {
      const imgsrc = e.dataTransfer.getData('imgsrc')
      if (!imgsrc) return
      this.$emit('update:modelValue', imgsrc.split('?')[0])
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-wrap {
  display: flex;
  .upload-handler {
    margin-right: 20px;
  }
  .preview {
    margin-bottom: 12px;
    max-height: 180px;
  }
}
</style>
